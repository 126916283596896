import { Toolbar, SaveButton, DeleteWithUndoButton } from "react-admin";
import { withStyles } from "@mui/styles";
import React from "react";
import BackButton from "../buttons/backButton.component";

const toolbarStyles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
};

export const CustomToolbar = withStyles(toolbarStyles)((props: any) => (
  <Toolbar>
    <SaveButton 
      alwaysEnable 
      label="Save (Custom)" 
      type="button"
      mutationOptions={{
        onError: (error) => {
          console.error("Save failed:", error);
        },
      }}
    />

    {!(props as any).noDelete && <DeleteWithUndoButton />}
  </Toolbar>
));

export default CustomToolbar;
